import React from 'react';
import img4 from '../assets/images/coverOne@2x.png';

class Component2 extends React.Component {
  render() {
    return (
      <div className='component-container'>
        <div className ='comp1-01'>
          02
        </div>
        <div className='comp2-box'>
          <img className='comp2-image' src={img4} alt='img4' />
          <div className='comp2-text-bottom'>
            We approve every event in order to curate experiences our members can’t find anywhere else.
          </div>
        </div>
        <div className='comp2-text-outlined'>
          INCREDIBLY UNIQUE
        </div>
        <div className='comp2-text-bold'>
          INCREDIBLY UNIQUE
        </div>
      </div>
    )
  };
}

export default Component2;