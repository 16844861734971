import React from 'react';
import img5 from '../assets/images/coverTwo@2x.png';

class Component3 extends React.Component {
  render() {
    return (
      <div className='component-container flex'>
        <div className ='comp1-01'>
          03
        </div>
        <div className='comp3-box-image'>
          <img className='comp3-image' src={img5} alt='img5' />
        </div>
        <div className='comp3-box-text'>
          <div className='comp3-text-outlined'>
            POWERFUL
          </div>
          <div className='comp3-text-bold'>
            RECOMMENDATIONS
          </div>
          <div className='comp3-text-bottom'>
            Instead of trying to remember who to invite, we'll suggest members you'd be excited to include.
          </div>
        </div>
        <div className='comp3-box-split-text'>
          <div className='comp3-split-text-outlined'>
            POWERFUL
          </div>
          <div className='comp3-split-text-bold'>
            RECOMMENDATIONS
          </div>
        </div>
      </div>
    )
  };
}

export default Component3;