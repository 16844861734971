import React from 'react';
import img5 from '../assets/images/coverThree@2x.png';

class Component4 extends React.Component {
  render() {
    return (
      <div className='component-container'>
        <div className ='comp1-01'>
          04
        </div>
        <div className='comp4-box-image'>
          <img className='comp4-image' src={img5} alt='img5' />
        </div>
        <div className='comp4-box-text'>
          <div className='comp4-text-outlined'>
            COMPLETE
          </div>
          <div className='comp4-text-bold'>
            CONTROL
          </div>
          <div className='comp4-text-bottom'>
            Events can be as intimate or as open as you’d like, with an ability to reach members around the world.
          </div>
        </div>
        <div className='comp4-box-split-text-1'>
          <div className='comp4-text-outlined'>
            COMPLETE
          </div>
          <div className='comp4-text-bold'>
            CONTROL
          </div>
        </div>
        <div className='comp4-box-split-text-2'>
          <div className='comp4-text-outlined reduce-margin'>
            LETE
          </div>
          <div className='comp4-text-bold'>
            TROL
          </div>
        </div>
      </div>
    )
  };
}

export default Component4;