import React from 'react';
import '../assets/Desktop.css';
import '../assets/Mobile.css';
import '../assets/Sidebar.css';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import ScrollableAnchor from 'react-scrollable-anchor'
import { configureAnchors } from 'react-scrollable-anchor';
import VisibilitySensor from 'react-visibility-sensor';
import Component0 from './component0.jsx';
import Component1 from './component1.jsx';
import Component2 from './component2.jsx';
import Component3 from './component3.jsx';
import Component4 from './component4.jsx';
import Component5 from './component5.jsx';

configureAnchors({offset: -30, scrollDuration: 600})

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      highlight: '00',
    };
    this.handleVP0 = this.handleVP0.bind(this);
    this.handleVP1 = this.handleVP1.bind(this);
    this.handleVP2 = this.handleVP2.bind(this);
    this.handleVP3 = this.handleVP3.bind(this);
    this.handleVP4 = this.handleVP4.bind(this);
    this.handleVP5 = this.handleVP5.bind(this);
  }

  handleVP0(isVisible) {
    if (true === isVisible) {
      return this.setState({
        highlight: '00',
      });
    };
  }

  handleVP1(isVisible) {
    if (true === isVisible) {
      return this.setState({
        highlight: '01',
      });
    };
  }

  handleVP2(isVisible) {
    if (true === isVisible) {
      return this.setState({
        highlight: '02',
      });
    };
  }

  handleVP3(isVisible) {
    if (true === isVisible) {
      return this.setState({
        highlight: '03',
      });
    };
  }

  handleVP4(isVisible) {
    if (true === isVisible) {
      return this.setState({
        highlight: '04',
      });
    };
  }

  handleVP5(isVisible) {
    if (true === isVisible) {
      return this.setState({
        highlight: '05',
      });
    };
  }

  render() {
    const components = [
      <ScrollableAnchor id='00'><VisibilitySensor intervalDelay={50} offset={{top:-120, bottom:-120}} onChange={this.handleVP0}><Component0 /></VisibilitySensor></ScrollableAnchor>,
      <ScrollableAnchor id='01'><VisibilitySensor intervalDelay={50} offset={{top:-120, bottom:-120}} onChange={this.handleVP1}><Component1 /></VisibilitySensor></ScrollableAnchor>,
      <ScrollableAnchor id='02'><VisibilitySensor intervalDelay={50} offset={{top:-120, bottom:-120}} onChange={this.handleVP2}><Component2 /></VisibilitySensor></ScrollableAnchor>,
      <ScrollableAnchor id='03'><VisibilitySensor intervalDelay={50} offset={{top:-120, bottom:-120}} onChange={this.handleVP3}><Component3 /></VisibilitySensor></ScrollableAnchor>,
      <ScrollableAnchor id='04'><VisibilitySensor intervalDelay={50} offset={{top:-120, bottom:-120}} onChange={this.handleVP4}><Component4 /></VisibilitySensor></ScrollableAnchor>,
      <ScrollableAnchor id='05'><VisibilitySensor intervalDelay={50} offset={{top:-120, bottom:-120}} onChange={this.handleVP5}><Component5 /></VisibilitySensor></ScrollableAnchor>,
    ]

    const rendered_components = components.map(component => {
      return (
        <div>
          <div className='sidebar-container'>
            <div className='apply-button'><a onClick={this.handleClick} href='#05'>APPLY</a></div>
            <div className='sidebar-numbers-container'>
              <div className={'sidebar-numbers' + (this.state.highlight === '00' ? ' highlight' : '')}><a href='#00'>00</a></div>
              <div className={'sidebar-numbers' + (this.state.highlight === '01' ? ' highlight' : '')}><a href='#01'>01</a></div>
              <div className={'sidebar-numbers' + (this.state.highlight === '02' ? ' highlight' : '')}><a href='#02'>02</a></div>
              <div className={'sidebar-numbers' + (this.state.highlight === '03' ? ' highlight' : '')}><a href='#03'>03</a></div>
              <div className={'sidebar-numbers' + (this.state.highlight === '04' ? ' highlight' : '')}><a href='#04'>04</a></div>
              <div className={'sidebar-numbers' + (this.state.highlight === '05' ? ' highlight' : '')}><a href='#05'>05</a></div>
            </div>
          </div>
          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            {component}
          </ScrollAnimation>
        </div>
      );
    });
    return (
      <div>
        {rendered_components}
      </div>
    )
  }
}

export default Main;