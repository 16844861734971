import React from 'react';

class Component5 extends React.Component {
  constructor() {
    super();
    this.state = {
      email_sent: false,
      incomplete: false,
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      details: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  update(field) {
    if ('phone_number' === field) {
      return e =>
      this.setState({
        phone_number: e.currentTarget.value.replace(/\D+/g, '')
      })
    }
    return e =>
      this.setState({
        [field]: e.currentTarget.value
      });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (
      this.state.first_name === '' ||
      this.state.last_name === '' ||
      this.state.phone_number === '' ||
      this.state.email === '' ||
      this.state.details === ''
    ) {
      return this.setState({
        email_sent: false,
        incomplete: true,
      })
    }
    const data = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        phone_number: this.state.phone_number,
        email: this.state.email,
        details: this.state.details,
      }
      , payload = JSON.stringify(data)
      , request = new XMLHttpRequest();

    request.open('POST', 'https://api.rayatheapp.com/api/public/events/submit-request', true);
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.send(payload)

    await this.setState({
      email_sent: true,
      incomplete: false,
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      details: '',
    })
  }

  render() {
    return (
      <div className='component-container'>
        <div className ='comp1-01'>
          05
        </div>
        <div className='comp05-box'>
          <div className='comp5-text-outlined'>
            APPLY NOW
          </div>
          <div className='comp5-text-bold'>
            APPLY NOW
          </div>
          <div className='comp5-text-1'>
            We are only beginning with a handful of event partners in each city. If you'd like to have your event or organization considered, please apply here.          </div>
          <form className='comp05-form' onSubmit={this.handleSubmit}>
            <div className='comp05-form-column-1'>
              <label>FIRST NAME</label><div className={this.state.incomplete ? 'incomplete-input' : 'hidden'}>*</div>
              <input onChange={this.update('first_name')} value={this.state.first_name} name="first_name" type="text" />
              <label>LAST NAME</label><div className={this.state.incomplete ? 'incomplete-input' : 'hidden'}>*</div>
              <input onChange={this.update('last_name')} value={this.state.last_name} name="last_name" type="text" />
            </div>
            <div className='comp05-form-column-2'>
              <label>PHONE NUMBER</label><div className={this.state.incomplete ? 'incomplete-input' : 'hidden'}>*</div>
              <input onChange={this.update('phone_number')} value={this.state.phone_number} name="phone_number" type="text" pattern="[0-9]*"/>
              <label>EMAIL</label><div className={this.state.incomplete ? 'incomplete-input' : 'hidden'}>*</div>
              <input onChange={this.update('email')} value={this.state.email} name="email" type="email" />
            </div>
            <div className='comp05-form-column-3'>
              <label>WHAT WOULD YOUR EVENT BE?</label><div className={this.state.incomplete ? 'incomplete-input' : 'hidden'}>*</div>
              <textarea onChange={this.update('details')} value={this.state.details} className='comp05-details-input' name="details" type="text" placeholder='Describe your event. What makes it an experience our members can’t find anywhere else? Why would Raya members want to attend?'/>
            </div>
            <button className='comp05-form-submit'>SUBMIT
              <div className={this.state.email_sent ? 'sent-email' : 'hidden'}>Your application was submitted.</div>
              <div className={this.state.incomplete ? 'incomplete-message' : 'hidden'}>Please fill out required fields.</div>
            </button>
          </form>
        </div>
      </div>
    )
  };
}

export default Component5;