import React from 'react';
import img1 from '../assets/images/coverOne.png';
import img2 from '../assets/images/coverTwo.png';
import img3 from '../assets/images/coverThree.png';

class Component0 extends React.Component {
  render() {
    return (
      <div className='component-container'>
      <div className='comp0-box-image'>
        <img src={img1} alt='img1' className='comp0-img1' />
        <img src={img2} alt='img2' className='comp0-img2' />
        <img src={img3} alt='img3' className='comp0-img3' />
        <div className ='comp0-00'>00</div>
      </div>
        <div className='comp0-text-outlined'>
          RAYA EVENTS
        </div>
        <div className='comp0-text-bold'>
          RAYA EVENTS
        </div>
        <div className='comp0-box-text'>
          <a href='#01'>SCROLL DOWN</a>
        </div>
      </div>
    )
  };
}

export default Component0;