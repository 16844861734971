import React from 'react';

class Component1 extends React.Component {
  render() {
    return (
      <div className='component-container'>
        <div className ='comp1-01'>
          01
        </div>
        <div className='comp1-box'>
          WE'VE BUILT A
          <span className='horizon'> POWERFUL </span>
          EVENTS PLATFORM TO HELP OUR MEMBERS
          <span className='horizon'> EXPERIENCE </span>
          REMARKABLE MOMENTS
          <span className='horizon'> AROUND THE WORLD. </span>
        </div>
      </div>
    )
  };
}

export default Component1;