import React from 'react';
import Main from './components/Main.jsx';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: false,
      error: false,
      i1: '',
      i2: '',
      i3: '',
      i4: '',
    };
    this.handleInput = this.handleInput.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  verify() {
    if (this.state.i1 === '3' &&
        this.state.i2 === '5' &&
        this.state.i3 === '4' &&
        this.state.i4 === '2')
      {
      return true;
    }
    return false;
  }

  async handleInput(event) {
    const field = event.currentTarget.name
      , next_ref_index = {
        i1: 'i2',
        i2: 'i3',
        i3: 'i4',
        i4: 'i4',
      }
      , next_field = next_ref_index[field];
    await this.setState({
      [field]: event.currentTarget.value,
    });
    if (this.state[field] !== '') {
      this.refs[next_field].focus();
    }
    if ('i4' === field && this.verify()) {
      this.setState({
        verified: true,
      });
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
    }
    if ('i4' === field && false === this.verify()) {
      this.setState({
        error: true,
      })
    }
  }

  async onKeyDown(event) {
    if (event.keyCode === 8) {
      const field = event.currentTarget.name
      , prev_ref_index = {
        i1: 'i1',
        i2: 'i1',
        i3: 'i2',
        i4: 'i3',
      }
      , prev_field = prev_ref_index[field];
    if ('i4' === field && '' !== event.currentTarget.value) {
      this.refs[field].focus();
      return this.setState({
        [field]: '',
        error: false,
      });
    }
    this.refs[prev_field].focus();
    await this.setState({
      [field]: ''
    });
    }
  }

  render() {
    if (this.state.verified) {
      return (
        <Main />
      )
    } else {
      return (
        <div  className='access-container'>
          ENTER CODE
          <form onSubmit={this.handleSubmit}>
            <input
              onChange={this.handleInput}
              onKeyDown={this.onKeyDown}
              value={this.state.i1}
              ref='i1'
              name='i1'
              className={'access-input' + (this.state.error ? ' red' : '')}
              type="text"
              pattern="[0-9]*"
              maxLength="1"
              autoFocus />
            <input
              onChange={this.handleInput}
              onKeyDown={this.onKeyDown}
              value={this.state.i2}
              ref='i2'
              name='i2'
              className={'access-input' + (this.state.error ? ' red' : '')}
              type="text"
              pattern="[0-9]*"
              maxLength="1" />
            <input
              onChange={this.handleInput}
              onKeyDown={this.onKeyDown}
              value={this.state.i3}
              ref='i3'
              name='i3'
              className={'access-input' + (this.state.error ? ' red' : '')}
              type="text"
              pattern="[0-9]*"
              maxLength="1" />
            <input
              onChange={this.handleInput}
              onKeyDown={this.onKeyDown}
              value={this.state.i4}
              ref='i4'
              name='i4'
              className={'access-input' + (this.state.error ? ' red' : '')}
              type="text"
              pattern="[0-9]*"
              maxLength="1" />
          </form>
          <div className={this.state.error ? 'error-message' : 'hidden'}>Invalid code.</div>
        </div>
      )
    }
  };
}

export default App;
